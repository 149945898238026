<template>
	<div class="flex mb-4">
		<div col="sm">One of three columns</div>
		<div col="sm">One of three columns</div>
		<div col="sm">One of three columns</div>
	</div>
</template>

<script>
export default {}
</script>
