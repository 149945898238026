<template>
	<div class="flex mb-4">
		<div col="sm">One of three columns</div>
		<div col="sm">One of three columns</div>
		<div col="sm">One of three columns</div>
		<button @click="analytics">Test</button>
	</div>
</template>

<script>
export default {
	components: {},
	methods: {
		analytics() {
			this.$analytics.logEvent('testing_analytics', { name: 'Testing' })
		}
	},
	metaInfo() {
		return {
			title: 'About Page'
		}
	}
}
</script>
